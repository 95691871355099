/* came with CRA */
.App {
  text-align: center;
}

body {
  /* color: white; */
  background-color: black;
  /* background-color: blue; */
}

@font-face {
  font-family: 'curlz';
  src: local('curlz'), url(./fonts/CurlzMT.ttf) format('truetype');
}

@font-face {
  font-family: 'gil';
  src: local('gil'), url(./fonts/Gill-Kayo-P-OT_20586.ttf) format('truetype');
}

:root {
  --bgColor: rgba(0, 0, 0);
  --themeColor: rgba(154, 17, 1, 0.9)
}


/* still useful?  */
/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
} 

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
